import React from "react";


const Loading = () => {


  return (
    <>
    <div class="loader book">
    <figure class="page"></figure>
    <figure class="page"></figure>
    <figure class="page"></figure>
    
  </div>
  <h1>ECRITURE DES LIVRES..</h1>

  </>
  );
};

export default Loading;
